import React, { useMemo, useState } from 'react'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import styled from 'styled-components'
import {
  append,
  isEmpty,
  map,
  omit,
  path,
  pipe,
  pluck,
  prop,
  propEq,
  reject,
  uniq
} from 'ramda'
import { gql, useApolloClient } from '@apollo/client'
import debounce from 'debounce-promise'
import { useSelector } from 'react-redux'
import { OnChangeValue, Options } from 'react-select'
import AsyncSelect from 'react-select/async'

import { CssUnit } from '@/types'
import { colors, fontSizes } from '@/styles'
import { useCityData, useNeighbourhoodData } from '@/lib/locations'
import { RootState } from '@/lib/redux'
import { isEmptyOrNull, wrapArray } from '@/utils'
import { getLocalBuildingUrl } from '@/utils/building'
import {
  CURRENT_LOCATION,
  getSearch,
  getUrlSlugs,
  useTranslatedCities
} from '@/utils/location'
import { stringify } from '@/utils/qs'
import useLocalStorage from '@/hooks/use-localstorage'
import {
  BuildingSearchIcon,
  CitySearchIcon,
  CompanySearchIcon,
  CurrentLocationSearchIcon,
  GraduationCap
} from '@/components/icons'
import { toast } from '@/components/toast-notifications'

import Control from './control'
import DropdownIndicator from './dropdown-indicator'
import GroupHeading from './group-heading'
import Option from './option'
import selectStyles from './select-styles'

const SEARCH_RESULTS = gql`
  query ($query: String) {
    search(query: $query) {
      cities(size: 4) {
        name
        state
        location {
          lat
          lon
        }
        slug
      }
      neighbourhoods(size: 4) {
        name
        city
        state
        slug {
          slug_name
          slug_city
        }
        location {
          lat
          lon
        }
      }
      universities(size: 4) {
        name
        city
        state
        slug {
          slug_name
          slug_city
        }
        location {
          lat
          lon
        }
      }
      companies(size: 4) {
        id
        name
      }
      buildings(size: 4) {
        id
        name
        full_street_name
        city
        state
      }
    }
  }
`

const SeparatorContainer = styled.div`
  display: flex;
  align-items: center;
`

const Separator = styled.span`
  display: flex;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
  font-size: ${fontSizes.regular};
  font-weight: 500;
  color: ${colors.black};
  text-transform: capitalize;
  align-items: center;
  padding-bottom: 1px;

  svg {
    height: 16px;
    width: 16px;
    margin-right: 8px;
  }
`

export type OptionType = {
  label: string | JSX.Element
  value: string
  url?: string
  history?: boolean
  slug?: string
}

export type HistoryType = {
  history: boolean
  options: OptionType[]
}

export type Styles = {}

type Props = {
  instanceId: string
  styles?: Styles
  forceClose?: boolean
  showLarge?: boolean
  height?: CssUnit
  width?: CssUnit
  placeholder?: string
  hideCity?: boolean
  customColor?: string
  id?: string
}

interface SearchResultBuilding {
  name: string
  id: string
  full_street_name: string
  city: string
  state: string
}

interface SearchResultCompany {
  id: string
  name: string
}

interface SearchResultCity {
  name: string
  state: string
  slug: string
  location: {
    lat: number
    lon: number
  }
}

interface SearchResultNeighbourhood {
  id: number
  name: string
  city: string
  state: string
  country_code: string
  slug: {
    slug_city: string
    slug_name: string
  }
  location: {
    lat: number
    lon: number
  }
}

interface ListingsFilters {
  cities: string[]
}

interface SearchResult {
  buildings: SearchResultBuilding[]
  companies: SearchResultCompany[]
  cities: SearchResultCity[]
  neighbourhoods: SearchResultNeighbourhood[]
  universities: SearchResultNeighbourhood[]
}

interface SearchResultData {
  search: SearchResult
}

type SearchResultVariables = {}
type OptionResultType =
  | OptionType
  | OptionType[]
  | Options<OptionType | OptionType[]>
  | HistoryType

function isMultiResultList(list: OptionResultType): list is OptionType[] {
  return Array.isArray(list)
}

function isSingleResultList(list: OptionResultType): list is OptionType {
  return !Array.isArray(list)
}

function getSelectionValue(selected: OptionResultType) {
  if (isSingleResultList(selected)) {
    return selected.value
  }
  if (isMultiResultList(selected)) {
    return pluck('value', selected)[0]
  }
}

function getSelectionUrl(selected: OptionResultType) {
  if (isSingleResultList(selected)) {
    return selected.url
  }
  if (isMultiResultList(selected)) {
    return pluck('url', selected)[0]
  }
}

function getSelectionSlug(selected: OptionResultType) {
  if (isSingleResultList(selected)) {
    return selected.slug
  }
  if (isMultiResultList(selected)) {
    return pluck('slug', selected)[0]
  }
}

const CitySelector: React.FC<Props> = ({
  instanceId,
  styles,
  showLarge,
  placeholder,
  hideCity,
  customColor,
  ...props
}) => {
  const router = useRouter()
  const client = useApolloClient()
  const { t } = useTranslation()
  const [searchHistory, setSearchHistory] = useLocalStorage('searchHistory', [])

  const { neighbourhoodSlug, citySlug } = getUrlSlugs(router)
  const { data: cityData } = useCityData(citySlug)
  const { data: neighborhoodData } = useNeighbourhoodData(
    neighbourhoodSlug,
    citySlug
  )

  const defaultOptionsList: OptionResultType[] = [
    {
      value: CURRENT_LOCATION,
      label: (
        <SeparatorContainer>
          <Separator>
            <CurrentLocationSearchIcon />
            {t(
              'b.search.edit.use_current_location.label',
              'Use current location'
            )}
          </Separator>
        </SeparatorContainer>
      )
    },
    ...(isEmptyOrNull(searchHistory)
      ? []
      : [
          {
            history: true,
            options: searchHistory
          }
        ])
  ]

  const [defaultOptions, setDefaultOptions] = useState(defaultOptionsList)

  const loadOptions = useMemo(
    () =>
      debounce(async query => {
        const { data, error } = await client.query<
          SearchResultData,
          SearchResultVariables
        >({
          query: SEARCH_RESULTS,
          variables: { query }
        })

        if (error) {
          toast(
            t(
              'Oops! Something went wrong when loading the search results. Please refresh the page and try again.'
            ),
            {
              title: t(
                'b.listings.view.location_unavailable.title',
                'Search Failed'
              ),
              type: 'error'
            }
          )
        }

        const citiesState = data.search.cities
        const neighborhoodsState = data.search.neighbourhoods
        const universitiesState = data.search.universities
        const buildingsState = data.search.buildings
        const companiesState = data.search.companies

        const options = [
          {
            value: CURRENT_LOCATION,
            label: (
              <SeparatorContainer>
                <Separator>
                  <CurrentLocationSearchIcon />
                  {t(
                    'b.search.edit.use_current_location.label',
                    'Use current location'
                  )}
                </Separator>
              </SeparatorContainer>
            )
          },
          ...(isEmptyOrNull(searchHistory) || query
            ? []
            : [
                {
                  history: true,
                  options: searchHistory
                }
              ]),
          {
            label: (
              <SeparatorContainer>
                <Separator>
                  <CitySearchIcon />
                  {t('b.listing.search.cities.label', 'Cities')}
                </Separator>
              </SeparatorContainer>
            ),
            options: citiesState.map(({ name, state, slug }) => {
              return {
                label: `${name}, ${state}`,
                value: name,
                slug
              }
            })
          },
          {
            label: (
              <SeparatorContainer>
                <Separator>
                  <CitySearchIcon />
                  {t('b.listing.search.neighbourhoods.label', 'Neighbourhoods')}
                </Separator>
              </SeparatorContainer>
            ),
            options: neighborhoodsState.map(({ name, slug, city, state }) => {
              return {
                label: `${name}, ${city}, ${state}`,
                value: name,
                slug: `${slug.slug_city}/${slug.slug_name}`
              }
            })
          },
          {
            label: (
              <SeparatorContainer>
                <Separator>
                  <GraduationCap />
                  {t('b.listing.search.universities.label', 'Universities')}
                </Separator>
              </SeparatorContainer>
            ),
            options: universitiesState.map(({ name, slug, city, state }) => {
              return {
                label: `${name}, ${city}, ${state}`,
                value: name,
                slug: `${slug.slug_city}/${slug.slug_name}`
              }
            })
          },
          {
            label: (
              <SeparatorContainer>
                <Separator>
                  <BuildingSearchIcon />
                  {t('b.listing.search.buildings.label', 'Buildings')}
                </Separator>
              </SeparatorContainer>
            ),
            options: buildingsState.map(
              ({ name, id, full_street_name, city, state }) => {
                return {
                  label: name,
                  value: name,
                  url: getLocalBuildingUrl(
                    {
                      name,
                      id,
                      full_street_name,
                      city,
                      state
                    },
                    router.locale
                  ),
                  parsedAddress: `${full_street_name}, ${city}, ${state}`
                }
              }
            )
          },
          {
            label: (
              <SeparatorContainer>
                <Separator>
                  <CompanySearchIcon />
                  {t(
                    'b.listing.search.companies.label',
                    'Property Management Companies'
                  )}
                </Separator>
              </SeparatorContainer>
            ),
            options: companiesState.map(({ name, id }) => {
              return {
                label: name,
                value: name,
                url: `/company/${id}`
              }
            })
          }
        ]
        return options
      }, 500),
    [client, t, searchHistory, router.locale]
  )

  const filters = useSelector<RootState, ListingsFilters | undefined>(
    path<ListingsFilters>(['listings', 'filters'])
  )
  const placeholderText =
    placeholder || t('Search a city, building, or company')

  const formatCitiesArray = pipe(wrapArray, map(prop('value')))
  const formatOptionsArray = map(city => ({ value: city, label: city }))

  const onSearch = (
    selection: OnChangeValue<OptionType | OptionType[], boolean>
  ) => {
    if (!selection) return
    const selectionValue = getSelectionValue(selection)
    const selectionUrl = getSelectionUrl(selection)
    const selectionSlug = getSelectionSlug(selection)

    // Update user's previous search history
    const SEARCH_HISTORY_MAX = 3
    setSearchHistory((prev: OptionType[]) => {
      if (selectionValue === CURRENT_LOCATION) return prev
      const updated = uniq([{ ...selection, history: true }, ...prev])
      const updatedLength = updated.length
      const updatedList =
        updatedLength > SEARCH_HISTORY_MAX
          ? [...updated.slice(0, updated.length - 1)]
          : updated

      // We need to set the defaultOptions here otherwise
      // react select won't update the values right away
      setDefaultOptions((prevOptions): OptionResultType[] => {
        return pipe<any, any, OptionResultType[]>(
          reject(propEq('history', true)),
          append({
            history: true,
            options: updatedList
          })
        )(prevOptions)
      })

      return updatedList
    })

    if (selectionUrl) {
      if (typeof window !== 'undefined') {
        window.open(selectionUrl, '_blank')
      }
      return false
    }

    const defaultUrl = '/rental-listings'

    const citiesArray = formatCitiesArray(selection)

    // When changing cities we always reset to first page, so can omit "page" field
    const searchWithoutPage = omit(['page'], getSearch(router))
    const search = {
      ...searchWithoutPage,
      cities: []
    }

    if (selectionSlug) {
      const url = `${defaultUrl}/city/${selectionSlug}`
      const searchString = stringify(search)
      return router.push(
        url + (isEmpty(searchString) ? '' : `?${searchString}`),
        undefined,
        {
          shallow: true
        }
      )
    }

    router.push(
      defaultUrl +
        '?' +
        stringify({
          ...search,
          cities: citiesArray
        }),
      undefined,
      {
        shallow: true
      }
    )
  }

  const hasSelection = !!filters?.cities?.length
  const defaultedCities =
    router.pathname.includes('rental-listings') && hasSelection && !hideCity
      ? formatOptionsArray(filters!.cities)
      : []

  const translatedCities = useTranslatedCities(defaultedCities)
  const hasneighbourhoodSlug = !isEmptyOrNull(neighborhoodData)
  const hasCityData = !isEmptyOrNull(cityData)
  const displayName = hasneighbourhoodSlug
    ? [
        {
          value: neighborhoodData.name,
          label: `${neighborhoodData.name}, ${neighborhoodData.city}, ${neighborhoodData.state}`
        }
      ]
    : hasCityData
    ? {
        value: cityData.name,
        label: `${cityData.name}, ${cityData.state}`
      }
    : translatedCities

  return (
    <AsyncSelect
      defaultOptions={defaultOptions}
      cacheOptions
      loadOptions={loadOptions as any}
      onChange={onSearch}
      value={displayName}
      placeholder={placeholderText}
      aria-label={placeholderText}
      styles={selectStyles({
        height: props.height,
        width: props.width || '100%',
        showLarge,
        ...styles
      })}
      components={{
        Control,
        GroupHeading,
        Option,
        DropdownIndicator: indicatorProps => (
          <DropdownIndicator customColor={customColor} {...indicatorProps} />
        )
      }}
      isSearchable
      instanceId={instanceId}
      {...props}
    />
  )
}

export default React.memo(CitySelector)
