import { i18n, useTranslation } from 'next-i18next'
import { ascend, omit, pick, prop, sort, without } from 'ramda'
import { gql, useQuery } from '@apollo/client'

import { DEFAULT_FILTERED_CITY } from '@/models/listings'
import { initializeApollo } from '@/lib/apollo'
import { initializeStore } from '@/lib/redux'
import { isEmptyOrNull } from '@/utils'
import { CURRENT_LOCATION, getSearch, getUrlSlugs } from '@/utils/location'
import { parse as parseQs } from '@/utils/qs'

const CITIES_IN_STATE_DATA_QUERY = gql`
  query ($input: String) {
    citiesInState(input: $input) {
      name
      country_code
      state
      location {
        lat
        lon
      }
      slug
    }
  }
`

const CITIES_DATA_QUERY = gql`
  query ($input: String) {
    cities(input: $input) {
      name
      country_code
      state
      location {
        lat
        lon
      }
      slug
    }
  }
`

const CITY_DATA_QUERY = gql`
  query ($input: String) {
    city(input: $input) {
      name
      country_code
      state
      location {
        lat
        lon
      }
      slug
    }
  }
`

const CITY_AND_BUILDING_DATA_QUERY = gql`
  fragment buildingListFields on Listing {
    bathrooms
    bedrooms
    gr_min_price
    gr_max_price
    gr_unit
    gr_count
    hide_unit_number
    unit_type_txt_id
    unit_type_scope_txt_id
    cover_photo_aws_s3_key
    listing_id
    price
    price_frequency
    is_hidden
    state_machine
    verified_state_machine
    furnished
    size
    unit_files {
      aws_s3_key
      position
      tag
    }
    landlords {
      identity_verified_state
    }
  }
  fragment listingFields on FullListing {
    street_name
    building_type
    building_name
    building_id
    full_street_name
    city
    state
    zip
    location {
      lat
      lon
    }
    bathrooms
    bedrooms
    size
    gr_min_size
    gr_min_price
    gr_max_price
    gr_unit
    hide_unit_number
    unit_type_txt_id
    unit_type_scope_txt_id
    cover_photo_aws_s3_key
    listing_id
    price
    price_frequency
    is_hidden
    state_machine
    verified_state_machine
    furnished
    unit_files {
      aws_s3_key
      position
      tag
    }
    landlords {
      identity_verified_state
    }
  }
  query ($input: String, $buildingInput: ListSearchInput!) {
    city(input: $input) {
      name
      country_code
      state
      location {
        lat
        lon
      }
      slug
    }
    listSearch {
      buildings(input: $buildingInput) {
        metadata {
          page
          page_size
          total_count
          listings_count
        }
        feed {
          building_id
          street_name
          building_type
          full_street_name
          city
          state
          zip
          building_name
          location {
            lat
            lon
          }
          building_files {
            aws_s3_key
            tag
          }
          listings {
            ...buildingListFields
          }
          listing_count
        }
      }
      featured(input: $buildingInput) {
        ...listingFields
      }
    }
  }
`

const LOCALITY_DATA_QUERY = gql`
  query ($input: LocalityInput!) {
    locality(input: $input) {
      name
      city
      state
      country_code
      type
      slug {
        slug_name
        slug_city
      }
      location {
        lat
        lon
      }
    }
  }
`

const CITY_AND_LOCALITY_DATA_QUERY = gql`
  fragment buildingListFields on Listing {
    bathrooms
    bedrooms
    gr_min_price
    gr_max_price
    gr_unit
    gr_count
    hide_unit_number
    unit_type_txt_id
    unit_type_scope_txt_id
    cover_photo_aws_s3_key
    listing_id
    price
    price_frequency
    is_hidden
    state_machine
    verified_state_machine
    furnished
    size
    unit_files {
      aws_s3_key
      position
      tag
    }
    landlords {
      identity_verified_state
    }
  }
  fragment listingFields on FullListing {
    street_name
    building_type
    building_name
    building_id
    full_street_name
    city
    state
    zip
    location {
      lat
      lon
    }
    bathrooms
    bedrooms
    size
    gr_min_size
    gr_min_price
    gr_max_price
    gr_unit
    hide_unit_number
    unit_type_txt_id
    unit_type_scope_txt_id
    cover_photo_aws_s3_key
    listing_id
    price
    price_frequency
    is_hidden
    state_machine
    verified_state_machine
    furnished
    unit_files {
      aws_s3_key
      position
      tag
    }
    landlords {
      identity_verified_state
    }
  }
  query (
    $input: LocalityInput!
    $cityInput: String!
    $buildingInput: ListSearchInput!
  ) {
    locality(input: $input) {
      name
      city
      state
      country_code
      type
      slug {
        slug_name
        slug_city
      }
      location {
        lat
        lon
      }
    }
    city(input: $cityInput) {
      name
      country_code
      state
      location {
        lat
        lon
      }
      slug
    }
    listSearch {
      buildings(input: $buildingInput) {
        metadata {
          page
          page_size
          total_count
          listings_count
        }
        feed {
          building_id
          street_name
          building_type
          full_street_name
          city
          state
          zip
          building_name
          location {
            lat
            lon
          }
          building_files {
            aws_s3_key
            tag
          }
          listings {
            ...buildingListFields
          }
          listing_count
        }
      }
      featured(input: $buildingInput) {
        ...listingFields
      }
    }
  }
`

const getCities = cities => {
  if (!cities.length) return [DEFAULT_FILTERED_CITY]
  return cities
}

export const useCitiesInState = (stateAlphaCode = '') => {
  const alphaCode = stateAlphaCode.toLowerCase()
  const {
    data = [],
    errors,
    loading
  } = useQuery(CITIES_IN_STATE_DATA_QUERY, {
    variables: {
      input: alphaCode
    }
  })
  const cities = data.citiesInState || []
  const sortedCities = sort(ascend(prop('name')), cities)
  return { data: sortedCities, errors, loading }
}

export const useCitiesData = (citySlugs = []) => {
  const { t } = useTranslation()
  const {
    data = [],
    errors,
    loading
  } = useQuery(CITIES_DATA_QUERY, {
    variables: {
      input: citySlugs
    }
  })
  const cities = data.cities || []
  const formattedCities = cities.map(cityData => {
    const translatedName = cityData.slug
      ? { translated: t(`cities:city_name_${cityData.slug}`, cityData.name) }
      : {}
    const translatedState = cityData.slug
      ? { translatedState: t(`states:${cityData.state}`, cityData.state) }
      : {}
    return { ...translatedName, ...translatedState, ...cityData }
  })
  return { data: formattedCities, errors, loading }
}

export const useCityData = citySlug => {
  const { t } = useTranslation()
  const noCitySlug = isEmptyOrNull(citySlug)
  const {
    data = {},
    errors,
    loading
  } = useQuery(CITY_DATA_QUERY, {
    skip: noCitySlug,
    variables: {
      input: citySlug
    }
  })
  const cityData = data.city || {}
  const translatedName = cityData.slug
    ? { translated: t(`cities:city_name_${cityData.slug}`, cityData.name) }
    : {}
  const translatedState = cityData.slug
    ? { translatedState: t(`states:${cityData.state}`, cityData.state) }
    : {}
  return {
    data: { ...translatedName, ...translatedState, ...cityData },
    errors,
    loading
  }
}

export async function withCityAndBuildingData(
  [ctx, args = {}],
  buildingInput,
  reduxStore
) {
  const { citySlug } = getUrlSlugs(ctx)
  const apolloClient = args.apolloClient || initializeApollo()
  const { data } = await apolloClient.query({
    query: CITY_AND_BUILDING_DATA_QUERY,
    variables: {
      input: citySlug,
      buildingInput
    }
  })
  const city = data.city || {}
  const buildingDataServer = pick(['listSearch'], data) || {}

  const translatedName = city.slug
    ? {
        translated:
          i18n?.t(`cities:city_name_${city.slug}`, city.name) || city.name
      }
    : {}
  const translatedState = city.slug
    ? {
        translatedState:
          i18n?.t(`states:${city.state}`, city.state) || city.state
      }
    : {}
  const cityData = { ...translatedName, ...translatedState, ...city }
  return [
    ctx,
    {
      reduxStore,
      apolloClient,
      cityData,
      buildingDataServer,
      neighborhoodData: {},
      ...args
    }
  ]
}

export const useNeighbourhoodData = (neighbourhoodSlug, citySlug) => {
  const { t } = useTranslation()
  const {
    data = {},
    errors,
    loading
  } = useQuery(LOCALITY_DATA_QUERY, {
    skip: !neighbourhoodSlug,
    variables: {
      input: {
        slug_name: neighbourhoodSlug,
        slug_city: citySlug
      }
    }
  })

  const locality = data.locality || {}
  const translatedName = locality.slug?.slug_name
    ? {
        translated: t(
          `seo:neighbourhood_name_${locality.slug.slug_name}_${locality.slug.slug_city}`,
          locality.name
        )
      }
    : {}
  const translatedState = locality.slug?.slug_name
    ? {
        translatedState: t(`seo:${locality.state}`, locality.state)
      }
    : {}
  return {
    data: { ...translatedName, ...translatedState, ...locality },
    errors,
    loading
  }
}

export async function withCityAndNeighborhoodData([ctx, args = {}]) {
  const { req, query } = ctx
  const { filters: filtersOverride = {} } = args
  const apolloClient = args.apolloClient || initializeApollo()
  const reduxStore = args.reduxStore || initializeStore()
  const { dispatch } = reduxStore
  const parsedQuery = parseQs(query)
  const search = getSearch(parsedQuery)
  const { citySlug, neighbourhoodSlug } = getUrlSlugs(ctx)
  const filters = omit(
    ['state_machine'],
    reduxStore.getState().listings.filters
  )

  let citiesFilter
  if (!citySlug) {
    citiesFilter = without([CURRENT_LOCATION], getCities(filters.cities))
  } else {
    citiesFilter = citySlug
      ?.split('-')
      ?.map(slug => slug.charAt(0).toUpperCase() + slug.slice(1))
      ?.join(' ')
  }
  const buildingInput = {
    ...filters,
    cities: [citiesFilter]
  }

  dispatch.listings.updateFilter({
    ...search,
    ...filtersOverride,
    cities: citiesFilter
  })

  if (!neighbourhoodSlug && !citySlug) {
    return [ctx, { apolloClient, cityData: {}, neighborhoodData: {}, ...args }]
  }

  if (!neighbourhoodSlug) {
    return withCityAndBuildingData([ctx, args], buildingInput, reduxStore)
  }

  const { data } = await apolloClient.query({
    query: CITY_AND_LOCALITY_DATA_QUERY,
    variables: {
      input: {
        slug_name: neighbourhoodSlug,
        slug_city: citySlug
      },
      cityInput: citySlug,
      buildingInput
    }
  })
  await dispatch.listings.updateSearchPreferences(req)

  const locality = data.locality || {}
  const cityData = data.city || {}
  const buildingDataServer = pick(['listSearch'], data) || {}

  const translatedName = locality.slug?.slug_name
    ? {
        translated:
          i18n?.t(
            `seo:neighbourhood_name_${locality.slug.slug_name}_${locality.slug.slug_city}`,
            locality.name
          ) || locality.name
      }
    : {}
  const translatedState = locality.slug?.slug_name
    ? {
        translatedState:
          i18n?.t(`states:${locality.state}`, locality.state) || locality.state
      }
    : {}

  const neighborhoodData = {
    ...translatedName,
    ...translatedState,
    ...locality
  }
  return [
    ctx,
    {
      reduxStore,
      apolloClient,
      cityData,
      neighborhoodData,
      buildingDataServer,
      ...args
    }
  ]
}

export const useStateData = (stateAlphaCode = '') => {
  const { t } = useTranslation()
  const alphaCode = stateAlphaCode.toLowerCase()
  const STATES = {
    ab: {
      name: t('states:Alberta'),
      shortName: t('states:AB'),
      slug: 'alberta'
    },
    bc: {
      name: t('states:British Columbia'),
      shortName: t('states:BC'),
      slug: 'british-columbia'
    },
    mb: {
      name: t('states:Manitoba'),
      shortName: t('states:MB'),
      slug: 'manitoba'
    },
    nb: {
      name: t('states:New Brunswick'),
      shortName: t('states:NB'),
      slug: 'new-brunswick'
    },
    nl: {
      name: t('states:Newfoundland and Labrador'),
      shortName: t('states:NL'),
      slug: 'newfoundland-and-labrador'
    },
    nt: {
      name: t('states:Northwest Territories'),
      shortName: t('states:NT'),
      slug: 'northwest-territories'
    },
    ns: {
      name: t('states:Nova Scotia'),
      shortName: t('states:NS'),
      slug: 'nova-scotia'
    },
    on: {
      name: t('states:Ontario'),
      shortName: t('states:ON'),
      slug: 'ontario'
    },
    pe: {
      name: t('states:Prince Edward Island'),
      shortName: t('states:PE'),
      slug: 'prince-edward-island'
    },
    qc: {
      name: t('states:Quebec'),
      shortName: t('states:QC'),
      slug: 'quebec'
    },
    sk: {
      name: t('states:Saskatchewan'),
      shortName: t('states:SK'),
      slug: 'saskatchewan'
    }
  }

  return stateAlphaCode ? STATES[alphaCode] || {} : STATES
}
