export const getBuildingUrl = building => {
  if (!building) return ''
  const { full_street_name, city, state } = building
  const format = str =>
    str
      .replace(/\s+/g, '-')
      .replace(/[^a-zA-Z0-9-]/g, '')
      .toLowerCase()

  const id = building.building_id || building.id
  const name = building.building_name || building.name
  const buildingName = name ? `${format(name)}-` : ''
  return `/building/${id}/${buildingName}apartment-building-${format(
    full_street_name
  )}-${format(city)}-${format(state)}`
}

export const getLocalBuildingUrl = (building, lang = 'en') => {
  const prefix = lang === 'en' ? '' : `/${lang}`
  const buildingUrl = getBuildingUrl(building)
  return `${prefix}${buildingUrl}`
}
